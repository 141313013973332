import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Information",
  "path": "/infoscreen.html"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Please select a information link:`}</p>
    <p><a parentName="p" {...{
        "href": "charactersinfoselectscreen.html"
      }}>{`info for characters`}</a>{`
`}<a parentName="p" {...{
        "href": "characters.html"
      }}>{`the characters`}</a>{`
`}<a parentName="p" {...{
        "href": "pickgifscreen.html"
      }}>{`Animated GIFs`}</a>{`
`}<a parentName="p" {...{
        "href": "fanart.html"
      }}>{`Fan Art`}</a>{`
`}<a parentName="p" {...{
        "href": "sagasummaries.html"
      }}>{`Saga Summaries`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      